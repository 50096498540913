import '../../css/TopNavBar.css'
import React, { Component } from 'react';
import ScoffableLogo from '../../images/scoffable_partner_centre_logo.svg';
import { Link } from 'react-router-dom';
import {GiNetworkBars} from 'react-icons/gi';
import {TiDocumentText} from 'react-icons/ti';
import {FaUser, FaClipboardCheck} from "react-icons/fa";
import {GoGear} from 'react-icons/go';
import {FaFilter} from 'react-icons/fa';
import {MdAccountBalance} from 'react-icons/md';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logout } from '../../actions/UserActions';
import { toggleVendorChecked } from '../../actions/VendorActions';
import { showOrders, showOrderStats } from '../../actions/OrderActions';
import {FaSignOutAlt} from 'react-icons/fa';
import withRouter from 'react-router-dom/withRouter';
import $ from 'jquery';
import ListIcon from "../../images/icons/navigation/list.svg";
import ReviewsIcon from "../../images/icons/reviews/reviews.svg"
import PaymentsIcon from "../../images/icons/payments/payments.svg";
import FaqIcon from '../../images/icons/faq/faqs.svg';
import {lowercaseHypen} from "../../utils/helpers/Helpers";
import {userHasPermission} from "../../utils/helpers/User";
import {
    VIEW_FAQS,
    VIEW_INVOICES,
    VIEW_ORDERS,
    VIEW_PAYOUTS,
} from "../../utils/helpers/Permission";
import {showReviews, showBankDetails, showCompliance, showContactDetails} from "../../utils/helpers/Navhelper";

class NavBar extends Component {


    hideVendorSelectionControls = () => {
        const pathname = this.props.location.pathname;
        const vendors = this.props.vendors;

        if(vendors.toList().isEmpty()){
            // No vendors to show, so hide the selection controls!
            return true;
        }

        /* I think the intent of this is to not show the vendor dropdown / selection controls on pages where they don't
         * change anything (faqs), or pages where the controls could be problematic (where contracts might be being signed etc)
         * If I'm being honest, I think this is a bit shonky, but anyway, I think this is the intent. */
        return pathname.includes("bank-details") || pathname.includes("user-management") || pathname.includes("faqs")
    }

    render() {
        const { user, vendors, immutableVendors, actions } = this.props;
        const vendorsList = vendors.toList();

        return (
            <nav id="navbar" className="navbar navbar-expand-md sticky-top navbar-light bg-white">

                {/* Main scoffable logo branding in top bar */}
                <Link to="/orders" className="navbar-brand">
                    <img alt="scoffable" src={ScoffableLogo} className="top_logo" />
                </Link>

                {/* This is a filter icon, which pops out a list of vendors on mobile. */}
                <ul className={"navbar-nav ml-auto mr-3 " + (this.hideVendorSelectionControls() && " d-none ")}>
                    <li className="nav-item">
                        <button id="vendor-filter-button-mobile" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobileVendorSelectionToggler" aria-controls="mobileVendorSelectionToggler" aria-expanded="false" aria-label="Toggle Vendor Selection">
                            <FaFilter className="filter-icon" size="28" />
                        </button>
                    </li>
                </ul>

                {/* This is the list of vendors on desktop + tablet */}
                <ul className="navbar-nav d-none d-md-inline-flex w-100 navbar-content">
                    <div className={"nav-item dropdown ml-auto " + (this.hideVendorSelectionControls() && " d-none")}>
                    {!this.hideVendorSelectionControls() && user && <div className="text-right d-inline mr-1"><h4 className="d-inline" style={{marginRight: `${30 + 20*vendorsList.count()}px`, paddingTop: "20px", lineHeight: "49px", verticalAlign: "middle"}}>{user.firstName} {user.surname}</h4></div>}
                        <button className="nav-link dropdown-toggle d-inline" id="vendorFilterDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {vendorsList.toJS().map((vendor, index) => {
                                const marginRight = 18 + 20*index;
                            return <img key={vendor.vendorID} alt="logo" className="rounded vendor-logo mr-1" src={vendor.logoURL} style={{position: 'absolute', right: `${marginRight}px`, top: "3px"}} />
                            })}
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="vendorFilterDropdown" >
                        {vendorsList.toJS().map((vendor, index) =>{
                            return ([
                                index !== 0 && <div className="dropdown-divider" key={"dropdownDivider" + vendor.vendorID} />,
                                <li key={vendor.vendorID} className="dropdown-item" onClick={() => actions.toggleVendorChecked(immutableVendors.set(vendor.vendorID, { ...vendor, selected: !vendor.selected }))} >
                                    <div className="form-check cursor-pointer" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <input data-cy={"vendor-filter-checkbox-"+lowercaseHypen(vendor.name)} className="form-check-input position-static form-control-lg" type="checkbox" onChange={() => {}} checked={vendor.selected} style={{display: "flex", flexDirection: "column", justifyContent: "center"}} />
                                        <img alt="vendor" src={vendor.logoURL} className="rounded vendor-logo" style={{ height: "30px", marginLeft: "15px", marginRight: "15px", display: "flex", flexDirection: "column", justifyContent: "center"}} />
                                        <span style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                            {vendor.name}
                                        </span>
                                    </div>
                                </li>
                            ])}
                        )}
                        </div>
                    </div>

                    {/* This is the logout icon for desktop + tablet */}
                    <div className={"nav-item dropdown " + (this.hideVendorSelectionControls() && "ml-auto")}>
                    {this.hideVendorSelectionControls() && user && <div className="text-right d-inline mr-1"><h4 className="d-inline">{user.firstName} {user.surname}</h4></div>}
                        <button className="nav-link dropdown-toggle caret-off d-inline" id="logoutDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <GoGear size="35" />
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                            <li className="dropdown-item">
                                <button className="nav-link" onClick={() => actions.logout()}>
                                    <FaSignOutAlt size="20" />Logout
                            </button>
                            </li>
                        </div>
                    </div>

                </ul>

                {/* This is the mobile hamburger menu icon that opens out the navigation to the other sections of the app */}
                <button id="navbar-button-mobile" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobileNavbarToggler" aria-controls="mobileNavbarToggler" aria-expanded="false" aria-label="Toggle Navigation">
                    <span className="navbar-toggler-icon" />
                </button>

                {/* This is the list of vendors on mobile */}
                <div id="mobileVendorSelectionToggler" aria-labelledby="vendor-filter-button-mobile" data-parent="#navbar" className="collapse navbar-collapse" >
                    <ul className="navbar-nav mr-auto mt-2 mt-lg-0 d-md-none">
                        {vendorsList.toJS().map((vendor) =>
                            ([
                                <div className="dropdown-divider" key={"dropdownDivider" + vendor.vendorID} />,
                                <li key={vendor.vendorID} className="dropdown-item" onClick={() => actions.toggleVendorChecked(immutableVendors.set(vendor.vendorID, { ...vendor, selected: !vendor.selected }))} >
                                    <div className="form-check cursor-pointer" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <input data-cy={"vendor-filter-mobile-checkbox-"+lowercaseHypen(vendor.name)} className="form-check-input position-static form-control-lg" type="checkbox" onChange={() => {}} checked={vendor.selected} style={{display: "flex", flexDirection: "column", justifyContent: "center"}} />
                                        <img alt="vendor" src={vendor.logoURL} className="rounded vendor-logo" style={{ height: "30px", marginLeft: "15px", marginRight: "15px", display: "flex", flexDirection: "column", justifyContent: "center"}} />
                                        <span style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                            {vendor.name}
                                        </span>
                                    </div>
                                </li>
                      
                            ])
                            )}
                    </ul>
                </div>

                {/* This is the mobile navigation menu */}
                <div id="mobileNavbarToggler" aria-labelledby="navbar-button-mobile" data-parent="#navbar" className="collapse navbar-collapse" >
                    <ul className="navbar-nav mr-auto mt-2 mt-lg-0 d-md-none">
                    <div className="dropdown-divider" />
                        <li>
                            {user && <span id="account-owner-nav-text" className="float-right">{user.firstName} {user.surname}'s Account</span>}
                        </li>

                        { userHasPermission(user, VIEW_ORDERS) && <><div className="dropdown-divider" />
                            <li data-cy="nav-mobile-orders" className="nav-item" onClick={() => {
                            $('#mobileNavbarToggler').collapse('hide')
                            actions.showOrders();
                            }}>
                            <Link to="/orders" className="nav-link text-danger">
                                <img src={ListIcon} style={{ height: 20, width: 20 }} alt="Orders Icon" /><span>Orders</span>
                            </Link></li>
                        </>}

                        { userHasPermission(user, VIEW_ORDERS) && <><div className="dropdown-divider" />
                            <li data-cy="nav-mobile-order-stats" className="nav-item" onClick={() => {
                            $('#mobileNavbarToggler').collapse('hide')
                            actions.showOrderStats();
                            }}>
                            <Link to="/orders" className="nav-link text-danger">
                                <GiNetworkBars size="20" /><span>Order Stats</span>
                            </Link></li>
                        </>}

                        { showReviews(this.props) && <><div className="dropdown-divider" />
                            <div><li data-cy="nav-mobile-reviews" className="nav-item" onClick={() => {
                            $('#mobileNavbarToggler').collapse('hide')
                            }}>
                            <Link to="/order-reviews" className="nav-link text-danger">
                                <img src={ReviewsIcon} style={{ height: 20, width: 20 }} alt="Reviews Icon" /><span>Reviews</span>
                            </Link></li></div>
                        </>}

                        { userHasPermission(user, VIEW_INVOICES) && <><div className="dropdown-divider" />
                            <div><li data-cy="nav-mobile-invoices" className="nav-item" onClick={() => {
                                $('#mobileNavbarToggler').collapse('hide')
                            }}>
                                <Link to="/invoices" className="nav-link text-danger">
                                    <TiDocumentText size="20" /><span>Invoices</span>
                                </Link></li></div>
                        </>}

                        { userHasPermission(user, VIEW_PAYOUTS) && <><div className="dropdown-divider" />
                            <div><li data-cy="nav-mobile-payouts" className="nav-item" onClick={() => {
                                $('#mobileNavbarToggler').collapse('hide')
                            }}>
                            <Link to="/payouts" className="nav-link text-danger">
                                <img src={PaymentsIcon} style={{ height: 20, width: 20 }} alt="Payouts Icon" /><span>Payouts</span>
                            </Link></li></div>
                        </>}

                        { showBankDetails(this.props) && <><div className="dropdown-divider" />
                            <div><li data-cy="nav-mobile-bank-details" className="nav-item" onClick={() => {
                                $('#mobileNavbarToggler').collapse('hide')
                            }}>
                            <Link to="/bank-details" className="nav-link text-danger">
                                <MdAccountBalance size="20" /><span>Bank Details</span>
                            </Link></li></div>
                        </>}

                        { showCompliance(this.props) && <><div className="dropdown-divider" />
                            <div><li data-cy="nav-mobile-compliance" className="nav-item" onClick={() => {
                                $('#mobileNavbarToggler').collapse('hide')
                            }}>
                            <Link to="/compliance" className="nav-link text-danger">
                                <FaClipboardCheck size="20" /><span>Compliance</span>
                            </Link></li></div>
                        </>}

                        { showContactDetails(this.props) && <><div className="dropdown-divider" />
                            <div><li data-cy="nav-mobile-contact-details" className="nav-item" onClick={() => {
                                $('#mobileNavbarToggler').collapse('hide')
                            }}>
                            <Link to="/user-management" className="nav-link text-danger">
                                <FaUser size="20" /><span>Contact Details</span>
                            </Link></li></div>
                        </>}

                        { userHasPermission(user, VIEW_FAQS) && <><div className="dropdown-divider" />
                            <div><li data-cy="nav-mobile-faqs" className="nav-item" onClick={() => {
                                $('#mobileNavbarToggler').collapse('hide')
                            }}>
                            <Link to="/faqs" className="nav-link text-danger">
                                <img src={FaqIcon} style={{ height: 20, width: 20, marginRight: "2px" }} alt="Faqs Icon" /><span>FAQs</span>
                            </Link></li></div>
                        </>}

                        {/* Mobile logout option. Always shown */}
                        <div className="dropdown-divider" />
                        <li className="nav-item" onClick={() => {
                            $('#mobileNavbarToggler').collapse('hide')
                        }}>
                            <button id="logout-mobile" className="nav-link" onClick={() => actions.logout()}>
                                <FaSignOutAlt size="20" /><span>Logout</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }

}

const mapStateToProps = (store) => {
    return {
        vendors: store.vendor.vendors,
        immutableVendors: store.vendor.vendors,
        user: store.user.user,
        orderReviewsDisabled: store.reviews.orderReviewsDisabled,
        complianceEnabled: store.vendor.complianceEnabled
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ logout, toggleVendorChecked, showOrders, showOrderStats }, dispatch)
    };
};



const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(NavBar)

export default withRouter(connectedComponent);
